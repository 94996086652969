import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_en_techleadmdx = graphql`
        query PageQuery_service_en_techleadmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/techlead/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Project & Tech Lead",
  "path": "/service/techlead",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "en",
  "icon": "bx-pointer",
  "tags": ["scrum", "agile"],
  "experience": [{
    "title": "FIFA Offside Technology",
    "task": "Project Lead / Solutions Architect",
    "tags": ["team", "lead", "solutions", "architect"],
    "outcome": ["Pipeline for streaming", "Communication with PO & PM", "Coordination between 2 - 3 teams", "Development of technical details", "3D visualisation with multi-angle cams"],
    "link": ["https://www.fifa.com/technical/media-releases/semi-automated-offside-technology-to-be-used-at-fifa-world-cup-2022-tm", "https://www.fifa.com/technical/football-technology/news/semi-automated-offside-tech-aids-referees-and-fans-at-fifa-club-world-cup-tm", "https://www.fifa.com/technical/football-technology/news/limb-tracking-technology-offers-new-array-of-possibilities"],
    "image": ["./images/consult/bs-fifa-app-offside-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "NFL Wild Card 2021",
    "task": "Back-end Lead",
    "tags": ["team", "lead", "back-end", "developer"],
    "outcome": ["2 Emmy® Awards", "Coordination between teams", "Full stability during stream", "Processing of data stream in realtime"],
    "link": ["https://www.theverge.com/2021/1/11/22224770/nickelodon-nfl-wild-card-new-orleans-saints-chicago-bears-spongebob-squarepants-slime/", "https://twitter.com/CBSSports/status/1348411821775085577", "https://www.linkedin.com/posts/beyondsports_sportsemmy-activity-6866302858023755776-USMI"],
    "image": ["./images/techlead/bs-nfl-nick-wildcard-01.jpg", "./images/techlead/bs-nfl-nick-wildcard-02.jpg"],
    "copyright": "Beyond Sports B.V. / Paramount Global"
  }, {
    "title": "Racing Game With Animals",
    "task": "Supervisor",
    "tags": ["supervisor", "consultant"],
    "outcome": ["Supervised team of 4 students", "Helped with technical overview", "Succesfully graduated students", "Provided dev knowledge & reviews"]
  }, {
    "title": "Sports Racing Tracker",
    "task": "Developer & Second Front-end Lead",
    "tags": ["developer", "second lead"],
    "outcome": ["Pixel-perfect web UI + smooth web app", "Real-time streaming of positions", "Scrum / task support for PM"],
    "link": null,
    "copyright": null
  }]
};
const layoutProps = {
  PageQuery_service_en_techleadmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "award-winning-products",
      "style": {
        "position": "relative"
      }
    }}>{`Award-winning products`}<a parentName="h1" {...{
        "href": "#award-winning-products",
        "aria-label": "award winning products permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`With my help as both a developer and (tech) lead on the back-end team, 2 Emmy® Awards were received by a proud company:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We could not be prouder and happier to have those #sportsemmy trophies finally in our office`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.linkedin.com/posts/beyondsports_sportsemmy-activity-6866302858023755776-USMI/"
        }}>{`Source`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}>{`Skills`}<a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`A proactive attitude`}</li>
      <li parentName="ul">{`Experienced in supervising teams up to 10+ persons`}</li>
      <li parentName="ul">{`Ability to break down complex requirements into actionable steps`}</li>
      <li parentName="ul">{`Experienced in understanding, migrating and refactoring large codebases`}</li>
      <li parentName="ul">{`Continuous communication with stakeholders (PO, PM, team members, extern)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      